import React from "react";
import {
  AboutContainer,
  InnerContainer,
  HeaderContainer,
  Title,
  ContentGrid,
  IntroText,
  SkillsContainer,
  SkillsList,
} from "./styles/About.styles";
import { ProjectsButton } from "./styles/Home.styles";
import { HiArrowNarrowRight } from "react-icons/hi";
import { scrollToSection } from "../util/scrollToSection";

const About = () => {
  return (
    <AboutContainer id="about">
      <InnerContainer>
        <HeaderContainer>
          <Title>
            <p>ABOUT ME</p>
          </Title>
          <div>
            Below you will find more information about me, what I do, and my
            current skills mostly in terms of web development.
          </div>
        </HeaderContainer>
        <ContentGrid>
          <IntroText>
            <h2>Get to know me!</h2>
            <p>
              Wrangling bugs and lassoing code into shape one commit at a time.
              While "cowboy coding" might have a reputation for being wild and
              untamed, I like to think of it as just a little extra flair for
              problem-solving in the fast lane.
            </p>
            <p>
              Armed with a trusty keyboard and a penchant for clean, scalable
              code, I’m always ready to ride into the sunset of deadlines,
              leaving a trail of sleek, efficient applications behind me. Saddle
              up, let's make something awesome!
            </p>
            <ProjectsButton onClick={() => scrollToSection("contact")}>
              CONTACT
              <span className="arrow-icon">
                <HiArrowNarrowRight />
              </span>
            </ProjectsButton>
            {/* Button stays in IntroText */}
          </IntroText>
          <SkillsContainer>
            <h3>My Skills</h3>
            <SkillsList>
              <span>JavaScript</span>
              <span>React</span>
              <span>Redux</span>
              <span>Node Js</span>
              <span>Express</span>
              <span>AWS</span>
              <span>SQL</span>
              <span>HTML</span>
              <span>CSS</span>
              <span>Jest</span>
              <span>GIT</span>
              <span>Github</span>
              <span>Responsive Design</span>
              <span>Communication</span>
              <span>Critical Thinking</span>
              <span>Debugging</span>
            </SkillsList>
          </SkillsContainer>
        </ContentGrid>
      </InnerContainer>
    </AboutContainer>
  );
};

export default About;
