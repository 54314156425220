import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import {
  HomeContainer,
  ContentContainer,
  IntroText,
  Description,
  ButtonWrapper,
  ProjectsButton,
} from "./styles/Home.styles";
import { scrollToSection } from "../util/scrollToSection";
import DynamicGreeting from "../util/dynamicGreeting";

const Home = () => {
  return (
    <HomeContainer id="home">
      <ContentContainer>
        <IntroText>
          <DynamicGreeting />
        </IntroText>
        <Description>
          I specialize in building and wrangling Websites and Web Applications
          that drive results and contribute to the success of every project.
          With a sharp focus on clean code and functional design, I make sure
          every line I write rides smoothly toward delivering exceptional
          digital experiences.
        </Description>
        <ButtonWrapper>
          <ProjectsButton onClick={() => scrollToSection("projects")}>
            PROJECTS
            <span className="arrow-icon">
              <HiArrowNarrowRight />
            </span>
          </ProjectsButton>
        </ButtonWrapper>
      </ContentContainer>
    </HomeContainer>
  );
};

export default Home;
